<template>
  <div class="app_home">
    <div class="home_wrap">
      <div class="home_title padding-tb">
        <span>
          您好！{{
            (user.company && user.company.companyName) || user.nickname
          }}
        </span>
        <span
          class="bg-yellow text-df vip"
          v-if="$store.getters.enterprise.cooperationStatus"
          >VIP
        </span>
      </div>
      <div class="home_notice">
        <div class="title">欢迎使用食品标签审核系统</div>
        <div class="text-white text-sm">
          恭喜您，现在就可以提交食品标签了，我们将为贵企业提供专业、高效的标签审核服务，为您的产品保驾护航。
        </div>
        <div class="margin-top-xs">
          <router-link to="/submit_tag">
            <span
              class="submit-tag"
              @click="MtaH5.clickStat('index', { tag: 'true' })"
              >提交标签
            </span>
          </router-link>
        </div>
        <div class="text-white text-sm margin-top-sm">
          您还可以向您的账户进行充值，账户余额可直接抵扣审核服务费用。
        </div>
        <div class="margin-top-xs">
          <router-link to="/add_money">
            <el-button
              type="info"
              @click="MtaH5.clickStat('index', { money: 'true' })"
              round
              class="text-white"
              >了解充值优惠
            </el-button>
          </router-link>
        </div>
      </div>
      <div class="min_title">最新动态</div>
      <div class="home_news">
        <news :news_arr="news_list"></news>
      </div>
      <div class="min_title">待办事项</div>
      <div class="steps_arr">
        <steps :step_arr="step_arr"></steps>
      </div>
    </div>
  </div>
</template>

<script>
import news from "@/components/home/news";
import steps from "@/components/home/steps";
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    news,
    steps,
  },
  data() {
    return {
      news_list: [
        {
          active: "active",
          title: "未读消息",
        },
        {
          active: "",
          num: "",
          title: "待付款",
        },
        {
          active: "",
          num: "",
          title: "已生成审核报告",
        },
        {
          active: "",
          num: "",
          title: "待开票金额",
        },
      ],
      step_arr: [
        {
          title: "注册",
          icon2: "iconxulie",
          icon: "iconzhuce",
          info: `验证手机<br />微信登录`,
          active: true,
        },
        {
          title: "提交食品标签",
          icon2: "iconxulie3",
          icon: "icontijiao",
          info: "提交标签信息<br />支付服务费用",
          active: false,
        },
        {
          title: "接收审核结果",
          icon2: "iconxulie2",
          icon: "iconjieshou",
          info: "接收审核结果<br />下载审核报告",
          active: false,
        },
      ],
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["user", "enterprise"]),
  },
  async created() {
    const { data } = await this.$api.stats();
    if (!data) return;
    this.news_list[0].num = data.unReadMessage || "0";
    this.news_list[1].num = data.waitPaidOrder || "0";
    this.news_list[2].num = data.unReadReport || "0";
    this.news_list[3].num = `￥${parseFloat(data.waitInvoiceAmount).toFixed(
      2
    )}`;

    const companie = await this.$api.companies_get();
    if (companie.errcode === 0) {
      this.$store.commit("set_enterprise", companie.data);
    }
  },
};
</script>

<style lang="scss" scoped>
.app_home {
  .submit-tag {
    padding: 10px 44px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 14px;
    background-color: #f2c811;
    display: inline-block;
  }
  .home_wrap {
    .home_title {
      font-weight: bold;
      font-size: 30px;
      .vip {
        padding: 1px 2px;
      }
    }
    .home_notice {
      background: rgb(51, 51, 51);
      padding: 30px 56px;
      .title {
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        line-height: 50px;
      }
    }
    .min_title {
      margin-top: 44px;
      font-weight: bold;
      font-size: 26px;
      color: rgb(33, 33, 33);
      line-height: 116px;
      border-top: 2px solid rgb(227, 225, 225);
    }
  }
}
</style>
