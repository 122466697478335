<template>
  <div class="news_list clr">
    <div
      class="box_news"
      v-for="(item, index) in news_arr"
      :key="item.id"
      :class="item.active"
      @click="news(index)"
    >
      <div class="news_info">
        <div>
          <div class="num">{{ item.num }}</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["news_arr"],
  methods: {
    news(e) {
      switch (e) {
        case 0:
          this.$router.push("/msg");
          this.MtaH5.clickStat("index", { msg: "true" });
          break;
        case 1:
          this.$router.push({ path: "/food", query: { id: 1 } });
          this.MtaH5.clickStat("index", { pay: "true" });

          break;
        case 2:
          this.$router.push({ path: "/food", query: { id: 4 } });
          this.MtaH5.clickStat("index", { ok: "true" });

          break;
        case 3:
          this.MtaH5.clickStat("index", { open: "true" });
          this.$router.push("/bill");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.news_list {
  display: flex;
  display: -moz-flex;
  display: -ms-flexbox;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
}

.box_news {
  width: 2rem;
  height: 1.69rem;
  border: 1px solid $yellow;
  float: left;
  margin-right: 0.53rem;
  background: #ffffff;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: $yellow;
    box-shadow: 3px 3px 6px rgba(242, 200, 17, 0.6);
  }
  .news_info {
    height: 169px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    /* 水平居中*/
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-pack: center; /* IE 10 */
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center; /* IE 11+,Firefox 22+,Chrome 29+,Opera 12.1*/
    /* 垂直居中 */
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-align: center; /* IE 10 */
    -webkit-align-items: center;
    -moz-align-items: center;
    text-align: center;
    .num {
      font-size: 35px;
      font-weight: bold;
    }
    .iconfont {
      font-size: 38px;
      padding-bottom: 30px;
    }
    .title {
      font-size: 16px;
    }
  }
}
.active {
  background: $yellow;
  .num {
    color: rgb(255, 0, 0);
  }
}
</style>
